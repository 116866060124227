import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.siteKey = this.data.get("site-key");

    document.addEventListener("turbo:frame-load", () => {
      this.loadRecaptcha();
    });

    document.addEventListener("shown.bs.modal", () => {
      this.renderRecaptcha();
    });
  }

  loadRecaptcha() {
    if (typeof grecaptcha === "undefined") {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.onload = () => {
        grecaptcha.ready(() => {
          this.renderRecaptcha();
        });
      };
      document.head.appendChild(script);
    }
  }

  renderRecaptcha() {
    const recaptchaContainer = document.querySelector("[data-id='captcha-container']");
    if (recaptchaContainer && !recaptchaContainer.hasChildNodes() && typeof grecaptcha !== "undefined") {
      this.widgetId = grecaptcha.render(recaptchaContainer, {
        sitekey: this.siteKey,
        theme: "dark",
      });
    }
  }

  resetRecaptcha() {
    if (typeof grecaptcha !== "undefined" && this.widgetId !== undefined) {
      grecaptcha.reset(this.widgetId);
    }
  }
}