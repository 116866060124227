import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'links', 'titles' ]

  changeLinksVisibility(event) {
    event.preventDefault();
    this.linksTargets.forEach((item) => {
      const parentLi = item.closest('li'); // Get the closest parent <li> element
      if (parentLi) { // Ensure the parent <li> exists
        if (parentLi.hasAttribute('hidden')) {
          parentLi.removeAttribute('hidden');
        } else {
          parentLi.setAttribute('hidden', '');
        }
      }
    });
    this.titlesTargets.forEach((item)  => {
      if(item.hasAttribute('hidden')) {
        item.removeAttribute('hidden');
      } else {
        item.setAttribute('hidden', '');
      };
    });
  }
}
